var CountryRegionsListNodeComponent = require("./country-regions-list-node.component");

var CountryRegionsListComponent = function () {
    var self = this;

    var $currentContext = $();
    var moduleOptions = {};
    var classMap = {};

    var listing = [];

    function getPrograms(region) {
        if (!region) {
            return Promise.reject({
                errorMsg: "Region isn't provided."
            });
        }

        var data = {
            region: region
        };

        return new Promise((resolve, reject) => {
            $.ajax(
                {
                    type: "GET",
                    url: "/api/regional-content/get-programs",
                    data: data,
                    success: resolve,
                    error: reject
                }
            )
        });
    }

    function handleProgramsResponse(response) {
        if (!response || !response.success) {
            return Promise.reject({errorMsg: "Programs can't be obtained from the response."})
        }

        return Promise.resolve(response.value);
    }

    function handleApiError(node, error) {
        node.showError();

        return Promise.reject(error);
    }

    function bindEvents() {
        listing.forEach(node => {
            node.on("click", function() {
                if(node.hasContent()) {
                    node.toggle();
                } else {
                    var region = node.getRegionCode();

                    node.lock();
                    node.setContent(null);
                    node.hideError();

                    getPrograms(region)
                        .then(handleProgramsResponse)
                        .then(programs => {
                            node.setContent(programs);
                            node.expand();
                        })
                        .finally(node.unlock)
                        .catch(error => handleApiError(node, error))
                }
            });
        });
    }

    function parseContext() {
       var $nodes = $(CountryRegionsListNodeComponent.SELECTOR, $currentContext);

       $nodes.each((index, entry) => {
           var $regionContext = $(entry);
            var regionNode = new CountryRegionsListNodeComponent();
            regionNode.init($regionContext);
            listing.push(regionNode);
       });
    }

    this.init = function (context, options) {
        $currentContext = context || $currentContext;
        $.extend(moduleOptions, options);

        parseContext();
        bindEvents();
    }
};

module.exports = CountryRegionsListComponent;
