var VideoBoxComponent = require("./video-box.component");
var VideoPreviewComponent = require("./video-preview.component");

var VideoPopUpComponent = function(){
    var $currentContext = $(document);
    var moduleOptions = {
        autoplay: true
    };
    var classMap = {
        modal: ".js-video-box__modal"
    };

    var $modal;

    var videoBox;

    function parseContext(){
        $modal = $(classMap.modal, $currentContext);
    }

    function initComponents(){
        VideoPreviewComponent.init($currentContext);
        videoBox = VideoBoxComponent.init($currentContext);
    }

    function bindEvents(){
        videoBox.on("play-button-click", function(){
            $modal.modal();
            
            if (moduleOptions.autoplay){
                videoBox.player.play();
            }
        })
        $modal.on("hidden.bs.modal", function () {
            videoBox.player.pause();
        });
    }

    this.init = function ($context, options){
        $currentContext = $context || $currentContext;
        $.extend(moduleOptions, options);
        
        parseContext();
        initComponents();
        bindEvents();
    }
}

VideoPopUpComponent.init = ($context, options) => {
    var videoPopUp = new VideoPopUpComponent();
    videoPopUp.init($context, options);

    return videoPopUp;
};

module.exports = VideoPopUpComponent;