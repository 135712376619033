var RollingNumberComponent = require("./rolling-number.component");

var RollingNumbersService = function () {
    var self = this;
    var $currentContext = $();
    var moduleOptions = {};
    var classMap = {};

    var rollingNumbersList = [];

    function isOutOfViewport(elementBounding, viewportBounding) {
        var out = {};
        out.top = elementBounding.top < viewportBounding.top;
        out.left = elementBounding.left < viewportBounding.left;
        out.bottom = elementBounding.bottom > viewportBounding.bottom;
        out.right = elementBounding.right > viewportBounding.right;
        out.any = out.top || out.left || out.bottom || out.right;
        out.all = out.top && out.left && out.bottom && out.right;

        return out;
    }

    this.isOutOfWindow = function (rollingNumber) {
        var contextBounding = rollingNumber.context.get(0).getBoundingClientRect();
        var windowBounding = {
            top: 0,
            left: 0,
            right: window.innerWidth || document.documentElement.clientWidth,
            bottom: window.innerHeight || document.documentElement.clientHeight
        };

        var isOut = isOutOfViewport(contextBounding, windowBounding);
        return isOut;
    };

    function startAnimation(rollingNumber) {
        var intervalId = setInterval(function () {
            if(rollingNumber.isAnimated) {
                clearInterval(intervalId); // see workaround for slick slider
            }
            var isOutOfWindow = self.isOutOfWindow(rollingNumber);
            if (!isOutOfWindow.any) {
                try {
                    rollingNumber.animate();
                } catch (e) {
                    console.error(e);
                } finally {
                    clearInterval(intervalId);
                }
            }
        }, 100);
    }

    function bindEvents() {
        rollingNumbersList.forEach(rollingNumber => {
            startAnimation(rollingNumber);

            // NOTE: workaround for the slick slider. When slider mode is infinity it creates several clones of the slide
            // so we need to prevent "separate" animation of the clones.
            rollingNumber.on("animation-started", function () {
                var currentId = rollingNumber.id;
                rollingNumbersList
                    .filter(entry => entry.id === currentId && rollingNumber !== entry)
                    .forEach(entry => entry.animate());
            })
        });
    }

    function parseContext() {
        var $rollingNumbers = $(RollingNumberComponent.SELECTOR, $currentContext);

        $rollingNumbers.each((index, entry) => {
            var $rollingNumberContext = $(entry);
            var rollingNumber = new RollingNumberComponent();
            rollingNumber.init($rollingNumberContext);
            rollingNumbersList.push(rollingNumber);
        });
    }

    this.init = function (context, options) {
        $currentContext = context || $currentContext;
        $.extend(moduleOptions, options);

        parseContext();
        bindEvents();
    };

    return this;
};

module.exports = RollingNumbersService;
