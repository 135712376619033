var GeolocationService = function () {
    var self = this;

    var moduleOptions = {
        regionCookieDurationDays: 30,
        regionCookieName: "User.Country.Region"
    };

    var regionsMap = {}, cookiesService;

    this.getUserRegion = function () {
        var regionCode = getUserRegionFromCache(); // cache
        if (regionCode) {
            var regionModel = buildRegionModel(regionCode);
            return Promise.resolve(regionModel);
        }

        return getCurrentLocation() // by coordinates
            .then(getRegionByLocation) // reverse geodcoding API call
            .then(handleRegionResponse) // validate & store in cache
            .catch(error => {
                console.log(error); // log error
                cacheUserRegion(null); // clean cache
                return Promise.resolve(null); // return nothing
            });
    }

    function getCurrentLocation() {
        if (!navigator.geolocation) {
            return Promise.reject({
                errorMsg: "Browser doesn't support geolocation API."
            });
        }

        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition((position) => {
                resolve({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });
            }, (positionError) => {
                reject({
                    errorMsg: positionError.message
                });
            });
        });
    }

    function getRegionByLocation(location) {
        if (!location) {
            return Promise.reject({
                errorMsg: "Location is not provided."
            });
        }

        return new Promise((resolve, reject) => {
            $.ajax(
                {
                    type: "GET",
                    url: "/api/geolocation/get-country-region",
                    data: location,
                    success: resolve,
                    error: reject
                }
            )
        });
    }

    function handleRegionResponse(reponse) {
        if (!reponse || !reponse.success || !reponse.value) {
            return Promise.reject({errorMsg: "Region can't be obtained from the response."})
        }

        var regionCode = reponse.value;
        var regionModel = buildRegionModel(regionCode);
        cacheUserRegion(regionCode);

        return Promise.resolve(regionModel);
    }

    function buildRegionModel(regionCode) {
        return {
            code: regionCode,
            name: regionsMap[regionCode]
        };
    }

    function getUserRegionFromCache() {
        return cookiesService.getCookie(moduleOptions.regionCookieName);
    }

    function cacheUserRegion(regionCode) {
        cookiesService.setCookie(moduleOptions.regionCookieName, regionCode, {
            expires: moduleOptions.regionCookieDurationDays,
            path: "/"
        });
    }

    this.init = function (options, regions) {
        $.extend(moduleOptions, options);
        $.extend(regionsMap, regions);

        cookiesService = $.pcg.frontend.cookiesService;
    }
};
module.exports = new GeolocationService();
