var GeolocationService = require("../../services/geolocation.service");

var RegionProgramContactsComponent = function () {
    var $currentContext = $();

    var moduleOptions = {};

    var classMap = {
        regionsDropdown: ".js-region-programs-contacts__regions",
        programs: ".js-region-programs-contacts__programs",
        undefinedRegionError: ".js-region-programs-contacts__undefined-region-error",
        apiError: ".js-region-programs-contacts__api-error"
    };

    var $regionsDropdown, $programs, $apiError, $undefinedRegionError;

    var renderPrograms = (html) => $programs.html(html);

    var showUndefinedRegionError = () => $undefinedRegionError.show();

    var showApiError = () => $apiError.show();

    var hideErrors = function () {
        $undefinedRegionError.hide();
        $apiError.hide();
    };

    function parseContext() {
        $regionsDropdown = $(classMap.regionsDropdown, $currentContext);
        $programs = $(classMap.programs, $currentContext);
        $undefinedRegionError = $(classMap.undefinedRegionError, $currentContext);
        $apiError = $(classMap.apiError, $currentContext);
    }

    function bindEvents() {
        $regionsDropdown.on("change", handleRegionChange)
    }

    function handleRegionChange() {
        hideErrors();

        var $selectedRegion = $(":selected", $regionsDropdown);
        var region = {
            code: $selectedRegion.val(),
            name: $selectedRegion.text()
        };
        getPrograms(region).then(handleProgramsResponse).catch(handleApiError).catch(logError);
    }

    function displayPrograms() {
        renderPrograms(null);
        hideErrors();

        GeolocationService.getUserRegion()
            .then(region => {
                return setCurrentRegion(region).catch(handleUndefinedRegion);
            })
            .then(region => {
                return getPrograms(region).then(handleProgramsResponse).catch(handleApiError);
            })
            .catch(logError);
    }

    function logError(error) {
        console.log(error);
    }

    function setCurrentRegion(region) {
        if (!region) {
            return Promise.reject({
                errorMsg: "Region isn't provided."
            });
        }

        $regionsDropdown.val(region.code);

        return Promise.resolve(region);
    }

    function handleUndefinedRegion(error) {
        renderPrograms(null);
        showUndefinedRegionError();

        return Promise.reject(error);
    }

    function handleApiError(error) {
        renderPrograms(null);
        showApiError();

        return Promise.reject(error);
    }

    function getPrograms(region) {
        if (!region) {
            return Promise.reject({
                errorMsg: "Region isn't provided."
            });
        }

        var data = {
            region: region.code
        };

        return new Promise((resolve, reject) => {
            $.ajax(
                {
                    type: "GET",
                    url: "/api/regional-content/get-programs-contacts",
                    data: data,
                    success: resolve,
                    error: reject
                }
            )
        });
    }

    function handleProgramsResponse(response) {
        if (!response || !response.success) {
            return Promise.reject({errorMsg: "Programs can't be obtained from the response."})
        }

        var programsHtml = response.value;
        renderPrograms(programsHtml);

        return Promise.resolve(programsHtml);
    }

    this.init = function (context, options) {
        $currentContext = context || $currentContext;
        $.extend(moduleOptions, options);

        parseContext();
        bindEvents();
        displayPrograms();
    }
};

module.exports = RegionProgramContactsComponent;
