var NotificationPaneComponent = function () {
    var self = this;

    var $currentContext = $();
    var moduleOptions = {};

    var classMap = {
        closeButton: ".js-pcg-notification-pane__close-btn"
    };

    var $closeButton;
    var subscriptions = {};

    this.id = () => $currentContext.data().id;
    this.regions = () => $currentContext.data().regions || [];


    this.show = function() {
        $currentContext.removeClass("d-none");
    };

    this.close = function() {
        $currentContext.addClass("d-none");
        var handlers = subscriptions["close"];
        if (handlers) {
            handlers.forEach(handler => handler());
        }
    };

    this.on = function (event, handler) {
        if (subscriptions.hasOwnProperty(event)) {
            subscriptions[event].push(handler);
        } else {
            subscriptions[event] = [handler];
        }
    };

    function bindEvents() {
        $closeButton.on("click",  function() {
            self.close();
        });
    }

    function parseContext() {
        $closeButton = $(classMap.closeButton, $currentContext);
    }

    this.init = function (context, options) {
        $currentContext = context || $currentContext;
        $.extend(moduleOptions, options);

        parseContext();
        bindEvents();
    };

    return this;
};

Object.defineProperty(NotificationPaneComponent, "SELECTOR", {
    enumerable: false,
    configurable: false,
    writable: false,
    value: ".js-notification-pane"
});

module.exports = NotificationPaneComponent;
