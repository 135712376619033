var ScriptLoader = function () {
    var cache = [];

    this.loadScript = function (url) {
        if (cache.indexOf(url) > -1) {
            return Promise.resolve();
        }
        return new Promise((resolve, reject) => {
            $.getScript(url, function () {
                cache.push(url);
                resolve();
            });
        })
    };
};

module.exports = new ScriptLoader();
