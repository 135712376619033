var CommentFormComponent = require("./comment-form.component");

var CommentFormsService = function() {
    var self = this;
    var $currentContext = $();
    var moduleOptions = {};
    var classMap = {};

    var commentForms = [];

    function handleCommentSubmission(form, data) {
        form.lock();

        $.ajax({
            url: data.url,
            type: data.method,
            data: data.formData,
            success: function(html) {
                var $html = $(html);
                $currentContext.replaceWith($html);
                $currentContext = $html;
                commentForms = [];
                parseContext();
                bindEvents();
            },
            error: function() {
                form.showError();
                form.unlock();
            }
        });
    }


    function bindEvents() {
        commentForms.forEach(commentForm => {
            commentForm.on("comment-submit", function (data) {
                handleCommentSubmission(commentForm, data);
            });
        });
    }

    function parseContext() {
        var $commentForms = $(CommentFormComponent.SELECTOR, $currentContext);

        $commentForms.each((index, entry) => {
            var $commentFormContext = $(entry);
            var commentForm = new CommentFormComponent();
            commentForm.init($commentFormContext);
            commentForms.push(commentForm);
        });
    }

    this.init = function (context, options) {
        $currentContext = context || $currentContext;
        $.extend(moduleOptions, options);

        parseContext();
        bindEvents();
    };

    return this;
};

module.exports = CommentFormsService;
