var PageHeaderComponent = function () {
    var self = this;

    var $currentContext = $();
    var moduleOptions = {};

    var classMap = {
        pageHeader: ".js-page-header",
        mobileMenuToggle: ".js-page-header__toggle",
        pageHeaderFixedDesktop: ".js-page-header__fixed--desktop",
        pageHeaderFixedMobile: ".js-page-header__fixed--mobile",
        notifications: ".js-page-header__notifications",
        pageContentDesktop: ".js-page-content--desktop",
        pageContentMobile: ".js-page-content--mobile"
    };

    var $pageHeader,
        $mobileMenuToggle,
        $pageHeaderFixedDesktop,
        $pageHeaderFixedMobile,
        $notifications,
        $pageContentDesktop,
        $pageContentMobile;

    function isMobileView() {
        return $mobileMenuToggle.is(":visible");
    }

    function getHeaderHeight() {
        return isMobileView() ? $pageHeaderFixedMobile.outerHeight() : $pageHeaderFixedDesktop.outerHeight();
    }

    function setNotificationsPosition() {
        if ($notifications.length) {
            if (isMobileView()) {
                $pageHeaderFixedMobile.prepend($notifications);
            } else {
                $pageHeaderFixedDesktop.prepend($notifications);
            }
        }
    }

    this.handleHeaderHeight = function () {
        setNotificationsPosition();

        var height = getHeaderHeight();

        if (isMobileView()) {
            $pageContentDesktop.css("margin-top", 0);
            $pageContentMobile.css("margin-top", height);
        } else {
            $pageContentMobile.css("margin-top", 0);
            $pageContentDesktop.css("margin-top", height);
        }
    }

    function scrollOverNavbar() {
        var activeClass = "pcg-header__info-row--border";

        if (window.scrollY > $pageContentDesktop.outerHeight()) {
            $pageHeaderFixedDesktop.addClass(activeClass);
        } else {
            $pageHeaderFixedDesktop.removeClass(activeClass);
        }
    }

    function handleScrollPosition() {
        // we have to scroll down due to fixed header if page url contains an anchor
        var anchor = window.top.location.hash;
        if (anchor) {
            var $anchorTarget = $();

            try {
                $anchorTarget = $(anchor);
            } catch {
                // ignore
            }

            if ($anchorTarget.length) {
                var offset = $anchorTarget.offset().top;
                var height = getHeaderHeight();
                var scroll = offset - height;

                $("html").scrollTop(scroll);
            }
        }
    }

    function handleToggleClick() {
        $pageHeader.toggleClass("active");
        $("body").toggleClass("pcg-noscroll");
        $mobileMenuToggle.toggleClass("active");

        self.handleHeaderHeight(); // handle rotation in mobile view
    }

    function bindEvents() {
        $(window)
            .on("resize", function () {
                self.handleHeaderHeight();
            })
            .on("hashchange", function () {
                handleScrollPosition();
            })
            .on("scroll", function () {
                scrollOverNavbar();
            });

        $mobileMenuToggle.on("click", function () {
            handleToggleClick();
        });
    }

    function parseContext() {
        $pageHeader = $(classMap.pageHeader, $currentContext);
        $mobileMenuToggle = $(classMap.mobileMenuToggle, $currentContext);
        $pageHeaderFixedDesktop = $(classMap.pageHeaderFixedDesktop, $currentContext);
        $pageHeaderFixedMobile = $(classMap.pageHeaderFixedMobile, $currentContext);
        $notifications = $(classMap.notifications, $currentContext);
        $pageContentDesktop = $(classMap.pageContentDesktop, $currentContext);
        $pageContentMobile = $(classMap.pageContentMobile, $currentContext);
    }

    this.init = function (context, options) {
        $currentContext = context || $currentContext;
        $.extend(moduleOptions, options);

        parseContext();
        bindEvents();
        this.handleHeaderHeight();
        handleScrollPosition();
    };

    return this;
};

module.exports = new PageHeaderComponent();
