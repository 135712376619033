var CollapsibleComponent = function () {
    var currentContext = $(document);
    var moduleOptions = {
    };

    this.init = function (context, options) {
        currentContext = context || currentContext;
        $.extend(moduleOptions, options);
        // set indexes for tinyMce collapsible panels on the page
        $(context).each(function (index) {
            var panel = $(this);
            var header = $(".js-header", panel);
            var content = $(".js-content", panel);
            header.attr("data-target", ".js-content-" + index);
            content.removeClass("js-content").addClass("js-content-" + index);
            content.on('show.bs.collapse hide.bs.collapse', function () {
                header.toggleClass("collapsed");
            });
        });
    };

    return this;
};

module.exports = CollapsibleComponent;
