function CookieService() {

    var self = this;

    var cookiesCategories = {
        preferences: ["assist_accessWidget", "User.Country.Region"]
    };

    function pageRefreshOnChange() {
        if (isCookiebotAvailable() && Cookiebot.dialog) {
            window.location.reload();
        }
    }

    function bindEvents() {
        window.addEventListener('CookiebotOnAccept', pageRefreshOnChange, false);
        window.addEventListener('CookiebotOnDecline', pageRefreshOnChange, false);
    }

    function isCookiebotAvailable() {
        return !!window.Cookiebot;
    }

    Object.defineProperties(self, {
        allowPreferences: {
            get() {
                return isCookiebotAvailable() ? Cookiebot.consent.preferences : false;
            }
        },
        allowStatistics: {
            get() {
                return isCookiebotAvailable() ? Cookiebot.consent.statistics : false;
            }
        },
        allowMarketing: {
            get() {
                return isCookiebotAvailable() ? Cookiebot.consent.marketing : false;
            }
        },
    })

    this.getCookie = function (cookieAlias) {
        var result = null;

        if (self.isCookieApproved(cookieAlias)) {
            result = $.cookie(cookieAlias);
        }

        return result;
    };

    this.setCookie = function(cookieAlias, cookieValue, cookieSettings) {
        if (self.isCookieApproved(cookieAlias)) {
            $.cookie(cookieAlias, cookieValue, cookieSettings);
        }
    };

    this.isCookieApproved =  function(cookieAlias) {
        var isCookieApproved = false;
        
        for (var category in cookiesCategories) {

            var isCookieInCategory = cookiesCategories[category].indexOf(cookieAlias) !== -1;
            var isUserAllowThisCategory = isCookiebotAvailable() && Cookiebot.consent && Cookiebot.consent[category];

            if (isCookieInCategory && isUserAllowThisCategory) {
                isCookieApproved = true;
                break;
            }
        }

        return isCookieApproved;
    };

    bindEvents();

    return this;
}

module.exports = new CookieService();