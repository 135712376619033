var CountryRegionsListNodeComponent = function () {
    var self = this;

    var $currentContext = $();

    var moduleOptions = {
        spinnerDelay: 1000
    };

    var classMap = {
        context: ".js-country-regions-list-node",
        name: ".js-country-regions-list-node__name",
        content: ".js-country-regions-list-node__content",
        spinner: ".js-country-regions-list-node__spinner",
        error: ".js-country-regions-list-node__error"
    };

    var $name, $content, $spinner, $error;

    var expanded = false;
    var hasContent = false;

    var subscriptions = {};

    var spinnerTimer;

    this.lock = function() {
        clearTimeout(spinnerTimer);
        spinnerTimer = setTimeout(() => {
            $spinner.show();
        }, moduleOptions.spinnerDelay)
    };

    this.unlock = function() {
        clearTimeout(spinnerTimer);
        $spinner.hide();
    };

    this.getRegionCode = function () {
        var data = $currentContext.data();
        return data.region;
    };

    this.expand = function () {
        expanded = true;
        $content.show();
    };

    this.collapse = function () {
        expanded = false;
        $content.hide();
    };

    this.toggle = function () {
        expanded = !expanded;
        expanded ? self.expand() : self.collapse();
    };

    this.hasContent = () => hasContent;

    this.setContent = function (html) {
        hasContent = !!html;
        $content.html(html);
    };

    this.showError = () => $error.show();

    this.hideError = () => $error.hide();

    this.on = function (event, handler) {
        if (subscriptions.hasOwnProperty(event)) {
            subscriptions[event].push(handler);
        } else {
            subscriptions[event] = [handler];
        }
    };

    function bindEvents() {
        $name.on("click", function () {
            var handlers = subscriptions["click"];
            if (handlers) {
                handlers.forEach(handler => handler());
            }
        })
    }

    function parseContext() {
        $name = $(classMap.name, $currentContext);
        $content = $(classMap.content, $currentContext);
        $spinner = $(classMap.spinner, $currentContext);
        $error = $(classMap.error, $currentContext);
    }

    this.init = function ($context) {
        $currentContext = $context;
        parseContext();
        bindEvents();
    }
};

Object.defineProperty(CountryRegionsListNodeComponent, "SELECTOR", {
    enumerable: false,
    configurable: false,
    writable: false,
    value: ".js-country-regions-list-node"
});

module.exports = CountryRegionsListNodeComponent;
