const GoTopButton = function () {
    let $currentContext = $();
    let $element = $();

    const moduleOptions = {
        breakPoint: 900,
        scrollSpeed: 300
    };

    const classMap = {
        button: ".js-go-top-button"
    };

    function setVisibility() {
        const currentPosition = $currentContext.scrollTop();
        const isVisible = currentPosition > moduleOptions.breakPoint;
        $element.toggleClass("pcg-go-top-button--visible", isVisible);
    }

    function initContext() {
        $element = $(classMap.button, $currentContext);
    }

    function bindEvents() {
        $currentContext.on("scroll", function () {
            setVisibility();
        });

        $element.on("click", function (e) {
            e.preventDefault();
            $("html, body").animate({scrollTop: 0}, moduleOptions.scrollSpeed);
        });
    }

    this.init = function (context, options) {
        $currentContext = context || $currentContext;
        $.extend(moduleOptions, options);

        initContext();
        bindEvents();
        setVisibility();
    }

    return this;
};

module.exports = GoTopButton;
