var loader = require("./script-loader.component");

var YoutubeProvider = function () {
    var playerApiPromise = new Promise((resolve, reject) => {
        window.onYouTubePlayerAPIReady = function () {
            resolve();
        }
    });

    this.createPlayer = function (frameId) {
        return loader.loadScript("https://www.youtube.com/player_api")
            .then(() => playerApiPromise)
            .then(function () {
                var ytPlayer = new YT.Player(frameId);
                return Promise.resolve({
                    play: function () {
                        ytPlayer.playVideo();
                    },
                    pause: function () {
                        ytPlayer.pauseVideo();
                    }
                });
            });
    };

    this.getPreviewImage = function (id) {
        return Promise.resolve(`https://img.youtube.com/vi/${id}/mqdefault.jpg`);
    }

    this.getEmbedUrl = function (videoId, allowCookies){
        if (allowCookies === true) {
            return `https://www.youtube.com/embed/${videoId}?rel=0&enablejsapi=1`;
        } else {
            return `https://www.youtube-nocookie.com/embed/${videoId}?rel=0&enablejsapi=1`;
        }
    }
};

module.exports = new YoutubeProvider();
