var RollingNumberComponent = function () {
    var self = this;
    var $currentContext = $();
    var moduleOptions = {
        duration: 3000
    };

    var classMap = {
        value: ".js-rolling-number__value"
    };

    var subscriptions = {};

    var $value;

    var _isAnimating = false, _isAnimated = false;

    Object.defineProperties(self, {
        id: {
            get() {
                return $currentContext.data().id;
            }
        },
        context: {
            get() {
                return $currentContext;
            }
        },
        isAnimating: {
            get() {
                return _isAnimating;
            }
        },
        isAnimated: {
            get() {
                return _isAnimated;
            }
        }
    });

    this.on = function (event, handler) {
        if (subscriptions.hasOwnProperty(event)) {
            subscriptions[event].push(handler);
        } else {
            subscriptions[event] = [handler];
        }
    };

    this.animate = function () {
        if (!self.isAnimating && !self.isAnimated) {
            _isAnimating = true;

            invokeHandlers("animation-started");

            $value.prop("counter", 0)
                .animate({
                    counter: $value.data().targetValue
                }, {
                    duration: $value.data().duration || moduleOptions.duration,
                    easing: "swing",
                    step: function (currentNumber) {
                        var formattedNumber = currentNumber.toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,");
                        $value.text(formattedNumber);
                    },
                    complete: function () {
                        _isAnimating = false;
                        _isAnimated = true;
                    }
                });
        }
    };

    function invokeHandlers(event) {
        var handlers = subscriptions[event];
        if (handlers) {
            handlers.forEach(handler => handler());
        }
    }

    function parseContext() {
        $value = $(classMap.value, $currentContext);
    }

    this.init = function (context, options) {
        $currentContext = context || $currentContext;
        $.extend(moduleOptions, options);

        parseContext();
    };

    return this;
};

Object.defineProperty(RollingNumberComponent, "SELECTOR", {
    enumerable: false,
    configurable: false,
    writable: false,
    value: ".js-rolling-number"
});

module.exports = RollingNumberComponent;
