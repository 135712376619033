var pageHeaderComponent = require("../page-header.component");
var NotificationPaneComponent = require("./notification-pane.component");
var NotificationModalComponent = require("./notification-modal.component");

var GeolocationService = require("../../services/geolocation.service");

var NotificationPanesService = function () {
    var STORAGE_KEY_NOTIFICATIONS = "User.Notifications";

    var $currentContext = $();
    var moduleOptions = {};
    var classMap = {};

    var panesList = [];

    function markAsClosed(paneId) {
        var closedPanesValue = sessionStorage.getItem(STORAGE_KEY_NOTIFICATIONS);
        var closedPanesList = !!closedPanesValue ? JSON.parse(closedPanesValue) : [];
        closedPanesList.push(paneId);
        sessionStorage.setItem(STORAGE_KEY_NOTIFICATIONS, JSON.stringify(closedPanesList));
    }

    function isClosedPane(paneId) {
        var closedPanesValue = sessionStorage.getItem(STORAGE_KEY_NOTIFICATIONS) || "";
        if (!closedPanesValue.length) {
            return false;
        }

        var closedPanesList = JSON.parse(closedPanesValue);
        var closedPaneIndex = closedPanesList.indexOf(paneId);

        return closedPaneIndex > -1;
    }

    function filterPanesByRegion(region){
        var filteredPanes = panesList.filter(pane =>
            region && region.code && pane.regions().indexOf(region.code) > -1
        );

        return Promise.resolve(filteredPanes);
    }

    function getGlobalPanes(panesList) {
        return panesList.filter(pane => !pane.regions().length);
    }

    function getPanesWithRegions(panesList) {
        return panesList.filter(pane => pane.regions().length);
    }

    function showPanes(panesList) {
        panesList.forEach(pane => {
            if (!isClosedPane(pane.id())) {
                pane.show();
            }
        });
    }

    function bindEvents() {
        panesList.forEach(pane => {
            pane.on("close", function () {
                markAsClosed(pane.id());
                pageHeaderComponent.handleHeaderHeight();
            });
        });
    }

    function parseContext() {
        var $panes = $(NotificationPaneComponent.SELECTOR, $currentContext);
        $panes.each((index, entry) => {
            var $paneContext = $(entry);
            var pane = new NotificationPaneComponent();
            pane.init($paneContext);
            panesList.push(pane);
        });

        var $modal = $(NotificationModalComponent.SELECTOR, $currentContext);
        if ($modal.length){
            var pane = new NotificationModalComponent();
            pane.init($modal);
            panesList.push(pane);
        }
    }

    this.init = function (context, options) {
        $currentContext = context || $currentContext;
        $.extend(moduleOptions, options);

        parseContext();
        bindEvents();
        showPanes(getGlobalPanes(panesList));

        if (getPanesWithRegions(panesList).length){
            GeolocationService.getUserRegion()
                .then(filterPanesByRegion)
                .then(showPanes)
                .catch(error => console.log(error));
        }
    };

    return this;
};

module.exports = NotificationPanesService;
