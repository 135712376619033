var youtubeProvider = require("./youtube-provider.component");
var vimeoProvider = require("./vimeo-provider.component");

var VideoPreviewComponent = function () {
    var self = this;

    var $currentContext = $(document);
    var moduleOptions = {};
    var classMap = {
        videoFrame: ".js-video-box__frame",
        preview: ".js-video-box__preview"
    };

    var $frame, $preview;

    Object.defineProperties(self, {
        videoId: {
            get(){
                return $frame.data("video-id");
            }
        },
        customPreview: {
            get(){
                return $preview.data("preview-image");
            }
        },
        videoProvider: {
            get(){
                return $frame.data("provider");
            }
        }
    })

    function setPreviewImage() {
        getPreviewImage()
        .then(setBackgroundImage)
        .catch(error => console.log(error));
    }

    var getPreviewImage = () => {
        if (!!self.customPreview){
            return Promise.resolve(self.customPreview);
        }

        switch (self.videoProvider){
            case "youtube":
                return youtubeProvider.getPreviewImage(self.videoId);
            case "vimeo":
                return vimeoProvider.getPreviewImage(self.videoId);
            default:
                return Promise.reject(new Error(`unknown video provider: ${self.videoProvider}`));
        }
    }

    function setBackgroundImage(imageSrc) {
        $preview.css("background-image", `url(${imageSrc})`);
    }

    function parseContext() {
        $frame = $(classMap.videoFrame, $currentContext);
        $preview = $(classMap.preview, $currentContext);
    }

   this.init = function ($context, options) {
        $currentContext = $context || $currentContext;
        $.extend(moduleOptions, options);

        parseContext();
        setPreviewImage();
    }
}

VideoPreviewComponent.init = ($context, options) => {
    var videoPreview = new VideoPreviewComponent();
    videoPreview.init($context, options);

    return videoPreview;
};

module.exports = VideoPreviewComponent;
