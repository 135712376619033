var GeolocationService = require("../../services/geolocation.service");

var UserRegionProgramsComponent = function () {
    var self = this;

    var $currentContext = $();

    var moduleOptions = {
        spinnerDelay: 250
    };

    var classMap = {
        regionInfo: ".js-user-region-programs__region-info",
        currentRegion: ".js-user-region-programs__current-region",
        changeRegion: ".js-user-region-programs__change-region",
        content: ".js-user-region-programs__content",
        spinner: ".js-user-region-programs__spinner",
        apiError: ".js-user-region-programs__api-error",
        undefinedRegionError: ".js-user-region-programs__undefined-region-error"
    };

    var $regionInfo, $currentRegion, $changeRegion, $content, $spinner, $apiError, $undefinedRegionError;

    var spinnerTimer;

    var showRegionInfo = () => $regionInfo.show();

    var hideRegionInfo = () => $regionInfo.hide();

    var setContent = (html) =>  $content.html(html);

    var showApiError = () => $apiError.show();

    var showUndefinedRegionError = () => $undefinedRegionError.show();

    var hideErrors = () => {
        $apiError.hide();
        $undefinedRegionError.hide();
    };

    function lock() {
        clearTimeout(spinnerTimer);
        spinnerTimer = setTimeout(() => {
            $spinner.show();
        }, moduleOptions.spinnerDelay)
    }

    function unlock() {
        clearTimeout(spinnerTimer);
        $spinner.hide();
    }

    function displayRegionalPrograms() {
        lock();
        setContent(null);
        hideErrors();

        GeolocationService.getUserRegion()
            .then(region => {
                return setCurrentRegion(region).catch(handleUndefinedRegion)
            })
            .then(region => {
                return getPrograms(region).then(handleProgramsResponse).catch(handleApiError)
            })
            .finally(unlock)
    }

    function setCurrentRegion(region) {
        if (!region) {
            return Promise.reject({
                errorMsg: "Region isn't provided."
            });
        }

        $currentRegion.text(region.name);
        showRegionInfo();

        return Promise.resolve(region);
    }

    function handleUndefinedRegion(error) {
        setContent(null);
        showUndefinedRegionError();

        return Promise.reject(error);
    }

    function getPrograms(region) {
        if (!region) {
            return Promise.reject({
                errorMsg: "Region isn't provided."
            });
        }

        var data = {
            region: region.code
        };

        return new Promise((resolve, reject) => {
            $.ajax(
                {
                    type: "GET",
                    url: "/api/regional-content/get-programs",
                    data: data,
                    success: resolve,
                    error: reject
                }
            )
        });
    }

    function handleProgramsResponse(response) {
        if (!response || !response.success) {
            return Promise.reject({errorMsg: "Programs can't be obtained from the response."})
        }

        var programsHtml = response.value;
        setContent(programsHtml);

        return Promise.resolve(programsHtml);
    }

    function handleApiError(error) {
        showApiError();

        return Promise.reject(error);
    }

    function handleRegionChange() {
        var $countryRegions = $(".js-country-regions-list");
        var $header = $(".js-page-header");

        var $regionsPosition = $countryRegions.eq(0).offset().top;
        var $headerHeight = $header.height();

        $("html").animate({
            scrollTop: $regionsPosition - $headerHeight
        }, 500);
    }

    function bindEvents() {
        $changeRegion.on("click", handleRegionChange);
    }

    function parseContext() {
        $regionInfo = $(classMap.regionInfo, $currentContext);
        $currentRegion = $(classMap.currentRegion, $currentContext);
        $changeRegion = $(classMap.changeRegion, $currentContext);
        $content = $(classMap.content, $currentContext);
        $spinner = $(classMap.spinner, $currentContext);
        $apiError = $(classMap.apiError, $currentContext);
        $undefinedRegionError = $(classMap.undefinedRegionError, $currentContext);
    }

    this.init = function (context, options) {
        $currentContext = context || $currentContext;
        $.extend(moduleOptions, options);

        parseContext();
        bindEvents();
        displayRegionalPrograms();
    }
};

module.exports = UserRegionProgramsComponent;
