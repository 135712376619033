var loader = require("./script-loader.component");

var VimeoProvider = function () {
    this.createPlayer = function (frameId) {
        return loader.loadScript("https://player.vimeo.com/api/player.js")
            .then(function () {
                var vimeoPlayer = new Vimeo.Player(frameId);
                return Promise.resolve({
                    play: function () {
                        vimeoPlayer.play();
                    },
                    pause: function () {
                        vimeoPlayer.pause();
                    }
                });
            });
    }

    this.getPreviewImage = function (videoId) {
        return new Promise((resolve,reject) => {
            $.get(`https://vimeo.com/api/v2/video/${videoId}.json`, data => {
                if (data.length > 0){
                    preview = data[0].thumbnail_large || "";
                    resolve(preview);
                } else {
                    reject();
                }
            }).fail(error => {
                reject(error);
            });
        })
    }

    this.getEmbedUrl = function (videoId, allowCookies){
        if (allowCookies === true) {
            return `https://player.vimeo.com/video/${videoId}?api=1`;
        } else {
            return `https://player.vimeo.com/video/${videoId}?api=1&dnt=1`;
        }
    }
};

module.exports = new VimeoProvider();
