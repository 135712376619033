var youtubeProvider = require("./youtube-provider.component");
var vimeoProvider = require("./vimeo-provider.component");

var VideoBoxComponent = function () {
    var self = this;

    var $currentContext = $(document);
    var moduleOptions = {};
    var classMap = {
        videoFrame: ".js-video-box__frame",
        playButton: ".js-video-box__play-button",
    };

    var $frame, $playButton;
    var player, provider, cookiesService;
    var subscriptions = {};

    Object.defineProperty(self, "player", {
        get: () => player
    });

    Object.defineProperties(self, {
        videoId: {
            get: () => $frame.data("video-id")
        },
        frameId: {
            get: () => $frame.attr("id")
        },
        providerName: {
            get: () => $frame.data("provider")
        }
    })

    function createPlayer() {
        return new Promise((resolve, reject) => {
            var createPlayerPromise = provider.createPlayer(self.frameId);

            createPlayerPromise.then(instance => {
                player = instance;
                resolve(instance);
            });
        })
    }

    function logError(errorMessage) {
        console.log(errorMessage);
    }

    this.on = function (event, handler) {
        if (subscriptions.hasOwnProperty(event)) {
            subscriptions[event].push(handler);
        } else {
            subscriptions[event] = [handler];
        }
    };

    function raiseEvent(event, data) {
        var handlers = subscriptions[event];
        if (handlers) {
            handlers.forEach(handler => handler(data));
        }
    }

    function bindEvents() {
        $playButton.on("click", function () {
            raiseEvent("play-button-click");
        })
    }

    function parseContext() {
        $frame = $(classMap.videoFrame, $currentContext);
        $playButton = $(classMap.playButton, $currentContext);
    }

    function getProvider(){
        switch (self.providerName){
            case "vimeo":
                return vimeoProvider;
                break;
            case "youtube":
                return youtubeProvider;
                break;
            default:
                logError(new Error(`unknown video provider: ${self.providerName}`));
                break;
        }
    }

    function setVideoUrl() {
        var videoUrl = provider.getEmbedUrl(self.videoId, cookiesService.allowMarketing);
        $frame.attr("src", videoUrl);
    }

   this.init = function ($context, options) {
        $currentContext = $context || $currentContext;
        $.extend(moduleOptions, options);
        parseContext();

        cookiesService = $.pcg.frontend.cookiesService;
        provider = getProvider();

        setVideoUrl();
        createPlayer()
            .then(bindEvents)
            .catch(logError);
    }
};
//TODO-VTA: move to factory
VideoBoxComponent.init = ($context, options) => {
    var videoBox = new VideoBoxComponent();
    videoBox.init($context, options);

    return videoBox;
};

module.exports = VideoBoxComponent;
