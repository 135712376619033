var CommentFormComponent = function() {
    var self = this;
    var $currentContext = $();
    var moduleOptions = {
        spinnerDelay: 250
    };

    var classMap = {
        spinner: ".js-comment-form__spinner",
        error: ".js-comment-form__error"
    };

    var $spinner, $error;

    var subscriptions = {};

    var isBusy = false;

    Object.defineProperties(self, {
        id: {
            get() {
                return $currentContext.data().id;
            }
        }
    });

    var spinnerTimer;
    this.lock = function() {
        isBusy = true;
        clearTimeout(spinnerTimer);
        spinnerTimer = setTimeout(() => {
            $spinner.show();
        }, moduleOptions.spinnerDelay)
    };

    this.unlock = function() {
        isBusy = false;
        clearTimeout(spinnerTimer);
        $spinner.hide();
    };

    this.showError = function () {
        $error.show();
    };

    this.on = function (event, handler) {
        if (subscriptions.hasOwnProperty(event)) {
            subscriptions[event].push(handler);
        } else {
            subscriptions[event] = [handler];
        }
    };

    function invokeHandlers(event, data) {
        var handlers = subscriptions[event];
        if (handlers) {
            handlers.forEach(handler => handler(data));
        }
    }

    function bindEvents() {
        $currentContext.on("submit", function(e) {
            e.preventDefault();

            if(!isBusy) {
                var $form = $(this);

                var eventData = {
                    url: $form.attr("action"),
                    method: $form.attr("method"),
                    formData: $form.serialize()
                };

                invokeHandlers("comment-submit", eventData)
            }
        })
    }

    function parseContext() {
        $spinner = $(classMap.spinner, $currentContext);
        $error = $(classMap.error, $currentContext);
    }

    this.init = function (context, options) {
        $currentContext = context || $currentContext;
        $.extend(moduleOptions, options);

        parseContext();
        bindEvents();
    };

    return this;
};

Object.defineProperty(CommentFormComponent, "SELECTOR", {
    enumerable: false,
    configurable: false,
    writable: false,
    value: ".js-comment-form"
});

module.exports = CommentFormComponent;
